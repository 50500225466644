/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $("header.banner .hamburguer a").click(function () {
          $("header.banner .menu").toggleClass("active");
        });

        $("header.banner .close-menu a").click(function () {
          $("header.banner .menu").toggleClass("active");
        });

        // Sticky header
        function stickyHeader() {
          var altura = $("header.banner").innerHeight();
          var header = $("header.banner"),
            scroll = $(window).scrollTop();

          if (scroll >= altura) {
            if (!$("header.banner").hasClass("sticky")) {
              header.addClass("sticky");
              $("body").addClass("header-is-sticky");
              $(".wrap > section:eq(0)").css({
                transition: "all 0.2s ease-in",
                "margin-top": $("header.sticky").innerHeight(),
              });
            }
          } else {
            header.removeClass("sticky");
            $("body").removeClass("header-is-sticky");
            $(".wrap > section:eq(0)").css("margin-top", "auto");
          }
        }

        stickyHeader();

        $(window).scroll(function () {
          stickyHeader();
        });

        var w = $(window).width();
        if (w < 768) {
          $("footer .footer-nav>li").click(function () {
            $(this).toggleClass("active");
            $(this).find("ul.sub-menu").slideToggle();
            $("footer .footer-nav>li")
              .not($(this))
              .each(function () {
                $(this).removeClass("active");
                $(this).find("ul.sub-menu").slideUp();
              });
          });
        } else {
          $("footer .footer-nav>li").each(function () {
            $(this).removeClass("active");
            $(this).find("ul.sub-menu").slideDown();
          });
        }

        if (w < 576) {
          $("header ul.nav>li").click(function () {
            $(this).toggleClass("active");
            $(this).find("ul.sub-menu").slideToggle();
            $("header ul.nav>li")
              .not($(this))
              .each(function () {
                $(this).removeClass("active");
                $(this).find("ul.sub-menu").slideUp();
              });
          });
        } else {
          $("header ul.nav>li").each(function () {
            $(this).removeClass("active");
            $(this).find("ul.sub-menu").slideDown();
          });
        }

        /* PESQUISA MOBILE */
        $("header .search-icon, header .search-close").click(function () {
          var width = $(window).width();

          if (width < 768) {
            $(".search-overlay").toggleClass("active");
          }
        });

        $("a.goto").click(function () {
          $("html,body").animate({ scrollTop: 0 }, "slow");
        });

        /* CARDS DE IDEIAS */
        $(document).on(
          "click",
          "#div_ideas_results .idea-wrapper .more a",
          function () {
            $("#div_ideas_results .idea-wrapper").each(function () {
              $(this).find(".description").slideUp();
              $(this).find(".more").show();
            });

            $(this).parent().parent().parent().find(".description").slideDown();
            $(this)
              .parent()
              .parent()
              .parent()
              .find(".more")
              .slideUp(function () {
                //resizeAllGridItems();
                setTimeout(function () {
                  $(this).hide();
                }, 500);
                initMasonry();
              });
          }
        );
        /* FIM LISTAGEM DE IDEIAS */

        if ($(".grid").length > 0) {
          var ja_votou = false;

          $(document).on("click", "a.idea-vote", function (event) {
            event.preventDefault();

            if (!ja_votou) {
              ja_votou = true;

              if (
                confirm("Deseja votar nesta ideia? A operação é irreversível!")
              ) {
                var ideia = $(this).data("ideia");
                var cor = $(this).data("cor");
                var url_atual = window.location.href;

                $("a.idea-vote").css("pointer-events", "none");

                var data = {
                  action: "votar_ideia",
                  post_type: "POST",
                  ideia: ideia,
                  cor: cor,
                };

                $.post(ajaxurl, data, function (response) {
                  if (response == 1) {
                    //$("#ideia_"+ideia).html(response);
                    //location.reload();
                    window.location.replace(url_atual);
                  }
                });
              }
            }
          });
        }

        /* ÂNCORAS */
        var hash = location.hash;
        if (hash) {
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop: $(hash).offset().top,
              },
              800
            );
        }

        /* FORMS */
        $(".wpcf7-submit").on("click", function () {
          $(this).css("pointer-events", "none"); // disable button after clicking on button
        });

        document.addEventListener(
          "wpcf7invalid",
          function (event) {
            $(".wpcf7-submit").css("pointer-events", "auto"); // enable button after getting respone
          },
          false
        );
        document.addEventListener(
          "wpcf7spam",
          function (event) {
            $(".wpcf7-submit").css("pointer-events", "auto"); // enable button after getting respone
          },
          false
        );
        document.addEventListener(
          "wpcf7mailsent",
          function (event) {
            $(".wpcf7-submit").css("pointer-events", "auto"); // enable button after getting respone
          },
          false
        );
        document.addEventListener(
          "wpcf7mailfailed",
          function (event) {
            $(".wpcf7-submit").css("pointer-events", "auto"); // enable button after getting respone
          },
          false
        );
        document.addEventListener(
          "wpcf7submit",
          function (event) {
            $(".wpcf7-submit").css("pointer-events", "auto"); // enable button after getting respone
          },
          false
        );
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        // banners
        if ($("section.banner").length > 0) {
          var gvideo = "";
          var giframe = "";
          var delay = parseInt($("section.banner").data("delay")) * 1000;
          var swiperBanners = new Swiper("section.banner .swiper-container", {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            watchOverflow: true,
            autoplay: {
              delay: delay,
            },
            pagination: {
              el: "section.banner .swiper-container .swiper-pagination",
              type: "bullets",
              clickable: true,
            },
            navigation: {
              nextEl: "section.banner .swiper-container .swiper-button-next",
              prevEl: "section.banner .swiper-container .swiper-button-prev",
            },
            on: {
              init: function (el) {
                var arr = Object.entries($(el));
                var video = $(
                  ".swiper-slide:eq(" + arr[0][1].activeIndex + ")"
                ).find("video");
                if (video.length) {
                  $(video).trigger("play");
                  gvideo = video;
                } else {
                  $(gvideo).trigger("pause");
                }

                var iframe = $(
                  ".swiper-slide:eq(" + arr[0][1].activeIndex + ")"
                )
                  .find("iframe")
                  .get(0); //iframe tag
                if (iframe != undefined && iframe.videoPlay != true) {
                  playIframeVideo(iframe);
                  giframe = iframe;
                } else {
                  pauseIframeVideo(giframe);
                }
              },
              slideChange: function (el) {
                var arr = Object.entries($(el));
                var video = $(
                  ".swiper-slide:eq(" + arr[0][1].activeIndex + ")"
                ).find("video");
                if (video.length) {
                  $(video).trigger("play");
                  gvideo = video;
                } else {
                  $(gvideo).trigger("pause");
                }

                var iframe = $(
                  ".swiper-slide:eq(" + arr[0][1].activeIndex + ")"
                )
                  .find("iframe")
                  .get(0); //iframe tag
                if (iframe != undefined && iframe.videoPlay != true) {
                  playIframeVideo(iframe);
                  giframe = iframe;
                } else {
                  pauseIframeVideo(giframe);
                }
              },
            },
          });

          function playIframeVideo(iframe) {
            if (iframe) {
              iframe.videoPlay = true; //init state
              iframe.contentWindow.postMessage(
                '{"event":"command","func":"playVideo","args":""}',
                "*"
              );
            }
          }

          function pauseIframeVideo(iframe) {
            if (iframe) {
              iframe.videoPlay = false; //init state
              iframe.contentWindow.postMessage(
                '{"event":"command","func":"pauseVideo","args":""}',
                "*"
              );
            }
          }
        }
        // end banners

        // bloco valores homepage e página matriz do bem comum
        if ($("#municipio").length > 0) {
          $("#municipio").select2({
            //dropdownParent: $('section.matrix .box')
          });

          $("#municipio").on("change", function () {
            var data = {
              action: "get_city_cloud",
              post_type: "POST",
              concelho: $(this).val(),
            };

            $.post(ajaxurl, data, function (response) {
              $("section.matrix #div_results").html(response);

              $("section.matrix #div_results a")
                .removeClass("selected")
                .css("background", "transparent");
              $("section.matrix #div_result_value").html("");
            });
          });
        }

        $(document).on("mouseover", ".values #div_results a", function () {
          var cor = $(this).data("cor");
          if (cor) {
            $(this).css("background-color", cor);
          }
        });

        $(document).on("mouseout", ".values #div_results a", function () {
          if (!$(this).hasClass("selected")) {
            $(this).css("background", "transparent");
          }
        });

        $(document).on("click", ".values #div_results a", function () {
          var valor = $(this).data("valor");
          var cor = $(this).data("cor");
          $(".values #div_results a")
            .removeClass("selected")
            .css("background", "transparent");
          $(this).addClass("selected").css("background-color", cor);

          //$("#div_result_value").html("");

          var data = {
            action: "get_valor",
            post_type: "POST",
            concelho: $("#municipio").val(),
            id: valor,
          };

          $.post(
            ajaxurl,
            data,
            function (response) {
              $("#div_result_value").html("").html(response);

              /*var width = $(window).width();
            if (width < 992) {
              var current_scroll = $(window).scrollTop();
              if (current_scroll != $(".value-wrapper").offset().top - 30) {
                $('html,body').animate({ scrollTop: $(".value-wrapper").offset().top - 30 }, 'slow');
              }
            }*/
            } /*, "json"*/
          );
        });

        $(".values #div_results a:eq(0)").click();
        // end bloco valores homepage e página matriz do bem comum

        // bloco ideias
        function getProjetos(id) {
          $("#ideia_municipio_projeto option:not(:first)").remove().end();

          var data = {
            action: "get_municipio_projetos",
            post_type: "POST",
            id: id,
          };

          $.post(ajaxurl, data, function (response) {
            $("#ideia_municipio_projeto").append(response);
          });
        }

        function getIdeias(id, projeto) {
          // $("#div_ideas_results").html("");

          var data = {
            action: "get_ideias",
            post_type: "POST",
            origem: "home",
            id: id,
            projeto: projeto,
          };

          $.post(
            ajaxurl,
            data,
            function (response) {
              $("#div_ideas_results").html(response);
              $(".grid").masonry("destroy");
              initMasonry();

              $("section.ideas a.btn").attr(
                "href",
                $("section.ideas a.btn").data("page") +
                  "?mun=" +
                  id +
                  "&proj=" +
                  projeto
              );
            } /*, "json"*/
          );
        }

        if ($("#ideia_municipio").length > 0) {
          $("#ideia_municipio, #ideia_municipio_projeto").select2({
            dropdownParent: $("section.ideas .select-wrapper"),
          });

          // getIdeias($("#ideia_municipio").val());
          $(".grid").masonry("destroy");
          initMasonry();

          $("#ideia_municipio").on("change", function () {
            getProjetos($(this).val());
            getIdeias($(this).val(), $("#ideia_municipio_projeto").val());
          });

          $("#ideia_municipio_projeto").on("change", function () {
            getIdeias($("#ideia_municipio").val(), $(this).val());
          });
        }
        // end bloco ideias

        // bloco notícias
        var swiper = new Swiper(".news .swiper-container", {
          loop: false,
          watchOverflow: true,
          pagination: {
            el: ".news .swiper-container .swiper-pagination",
            type: "bullets",
            clickable: true,
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          },
        });
        // end bloco notícias
      },
    },
    // About us page, note the change from about-us to about_us.
    registo: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        // colocar atributo maxlength de alguns campos a 500 para não limitar a 20 caracteres
        $(
          "#estabelecimento_ensino, #nome_encarregado, #input_box_1611689032"
        ).attr("maxlength", "500");

        // insere todos os concelhos nos dropdowns dos municípios onde vive e estuda
        if ($("#municipio_vive").length > 0) {
          $("#municipio_vive").children("option")[1].remove(); //remove 1st child
          $("#municipio_estuda").children("option")[1].remove(); //remove 1st child

          var data = {
            action: "get_concelhos",
            post_type: "POST",
          };

          $.post(
            ajaxurl,
            data,
            function (response) {
              $("#municipio_vive, #municipio_estuda").append(response);
            } /*, "json"*/
          );
        }

        // adiciona uma div a seguir aos dropdowns de municípios para apresentar informação de existirem ou não projetos ativos
        $('<div class="register-warnings"><span></span></div>').insertAfter(
          ".ur-form-row:eq(4)"
        );

        // o botão submeter não fica disponível inicialmente até selecionar os municípios e verificar se algum tem projetos ativos
        //$("button[type='submit']").addClass("disabled");

        // Quando seleciona um município dos dropdown faz a verificação
        // - erro: nenhum dos municípios tem projetos ativos
        // - sucesso: o município X tem o projeto Y ativo
        var projetos_vive = 0;
        var projetos_estuda = 0;
        var projetos_vive_msg = "";
        var projetos_estuda_msg = "";
        $(document).on(
          "change",
          "#municipio_vive, #municipio_estuda",
          function () {
            var sel = $(this).attr("id");
            var id = $(this).val();

            var data = {
              action: "get_projetos",
              post_type: "POST",
              id: id,
            };

            $.post(
              ajaxurl,
              data,
              function (response) {
                var spl = response.split(";;");
                if (sel == "municipio_vive") {
                  projetos_vive = parseInt(spl[0]);
                } else {
                  projetos_estuda = parseInt(spl[0]);
                }

                if (!projetos_vive && !projetos_estuda) {
                  projetos_vive_msg = "";
                  projetos_estuda_msg = "";

                  $(".register-warnings")
                    .removeClass("success")
                    .addClass("error")
                    .fadeIn();
                  $(".register-warnings span").html(
                    "O bem comum ainda não se realiza no teu município."
                  );
                  // como não existem projetos ativos para os municípios selecionados o botão submeter fica novamente indisponível
                  //$("button[type='submit']").addClass("disabled");
                } else {
                  if (sel == "municipio_vive") {
                    if (projetos_vive) {
                      projetos_vive_msg = spl[1];
                    } else {
                      projetos_vive_msg = "";
                    }
                  }
                  if (sel == "municipio_estuda") {
                    if (projetos_estuda) {
                      projetos_estuda_msg = spl[1];
                    } else {
                      projetos_estuda_msg = "";
                    }
                  }

                  if (projetos_vive_msg || projetos_estuda_msg) {
                    $(".register-warnings")
                      .removeClass("error")
                      .addClass("success")
                      .fadeIn();
                    var msg = "";
                    if (projetos_vive_msg) {
                      msg += projetos_vive_msg;
                    }
                    if (projetos_estuda_msg) {
                      if (!msg) {
                        msg += projetos_estuda_msg;
                      } else {
                        msg += "<br>" + projetos_estuda_msg;
                      }
                    }
                    $(".register-warnings span").html(msg);
                    // como existem projetos ativos para o município selecionado o botão submeter fica disponível
                    //$("button[type='submit']").removeClass("disabled");
                  }
                }
              } /*, "json"*/
            );
          }
        );

        // verifica se tem entre 14 e 18 anos
        // adiciona uma div para aviso de que a idade não permite registar
        $(
          '<div class="register-age"><span>Só aceitamos registos de utilizadores entre os 14 e os 18 anos.</span></div>'
        ).insertAfter(".ur-form-row:eq(-1)");

        function calculate_age(dob) {
          var diff_ms = Date.now() - dob.getTime();
          var age_dt = new Date(diff_ms);

          return Math.abs(age_dt.getUTCFullYear() - 1970);
        }

        $("#load_flatpickr").change(function () {
          var data_sel = $(this).val().split("-");
          var age = calculate_age(
            new Date(
              data_sel[0],
              parseInt(data_sel[1]) - 1,
              parseInt(data_sel[2])
            )
          );

          if (age < 14 || age > 18) {
            $(".register-age").addClass("error").fadeIn();
            $("button[type='submit']").addClass("disabled");
          } else {
            $(".register-age").removeClass("error").fadeOut();
            $("button[type='submit']").removeClass("disabled");
          }
        });

        if ($("form #utilizador_valores_field").length > 0) {
          $("form #utilizador_valores_field ul").html("");

          var data = {
            action: "get_valores",
            post_type: "POST",
          };

          $.post(ajaxurl, data, function (response) {
            $("form #utilizador_valores_field ul").html(response);
          });

          // só permite selecionar 3 valores
          $(document).on(
            "click",
            "form #utilizador_valores_field ul li input[type=checkbox], form #utilizador_valores_field ul li label",
            function () {
              if (
                $(
                  "form #utilizador_valores_field ul li input[type=checkbox]:checked"
                ).length == 3
              ) {
                $(
                  "form #utilizador_valores_field ul li input[type=checkbox]:not(:checked)"
                ).attr("disabled", true);
              } else {
                $(
                  "form #utilizador_valores_field ul li input[type=checkbox]:not(:checked)"
                ).attr("disabled", false);
              }
            }
          );
        }

        // impedir que removam a classe "disabled" do botão e submetam o formulário sem ter um município com projetos ativos selecionado
        $("button[type='submit']").click(function (e) {
          if ($(".register-age").hasClass("error")) {
            e.preventDefault();
            return false;
          }
          $("#ident").val($("#user_email").val());
        });

        // wait to element appears on DOM
        var waitForEl = function (selector, callback) {
          if (jQuery(selector).length) {
            callback();
          } else {
            setTimeout(function () {
              waitForEl(selector, callback);
            }, 100);
          }
        };

        waitForEl("#ur-submit-message-node", function () {
          var data = {
            action: "redirect_registo_completo",
            post_type: "POST",
            email: $("#ident").val(),
          };

          $.post(ajaxurl, data, function (response) {
            location.href = response;
          });
        });
      },
    },
    registo_completo: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        initMasonry();
      },
    },
    user_registration_page: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        // insere todos os concelhos nos dropdowns dos municípios onde vive e estuda
        // verifica quais o utilizador selecionou e coloca como selecionado nas dropdows
        if ($("#user_registration_municipio_vive").length > 0) {
          /*$("#user_registration_municipio_vive").children("option")[1].remove(); //remove 1st child
          $("#user_registration_municipio_estuda").children("option")[1].remove(); //remove 1st child*/

          var data = {
            action: "get_concelhos",
            post_type: "POST",
            option: "1",
            email: $("#user_registration_user_email").val(),
          };

          $.post(
            ajaxurl,
            data,
            function (response) {
              $("#user_registration_municipio_vive").append(response);
            } /*, "json"*/
          );

          var data2 = {
            action: "get_concelhos",
            post_type: "POST",
            option: "2",
            email: $("#user_registration_user_email").val(),
          };

          $.post(
            ajaxurl,
            data2,
            function (response) {
              $("#user_registration_municipio_estuda").append(response);
            } /*, "json"*/
          );
        }

        // adiciona menus com o link para o formulário de submeter uma ideia, para as ideias submetidas pelo utilizador e pelos votos que fez
        if ($("body.submeter-ideia").length == 0) {
          $(
            '<li><a href="' +
              siteurl +
              '/minha-conta/submeter-ideia/">Submeter Ideia</a></li>'
          )
            .addClass(
              "user-registration-MyAccount-navigation-link user-registration-MyAccount-navigation-link--submit-idea"
            )
            .insertBefore(
              "nav.user-registration-MyAccount-navigation ul li:eq(3)"
            );
          $(
            '<li><a href="' +
              siteurl +
              '/minha-conta/as-minhas-ideias/">As minhas ideias</a></li>'
          )
            .addClass(
              "user-registration-MyAccount-navigation-link user-registration-MyAccount-navigation-link--my-ideas"
            )
            .insertBefore(
              "nav.user-registration-MyAccount-navigation ul li:eq(4)"
            );
          $(
            '<li><a href="' +
              siteurl +
              '/minha-conta/as-minhas-votacoes/">Votações</a></li>'
          )
            .addClass(
              "user-registration-MyAccount-navigation-link user-registration-MyAccount-navigation-link--my-votes"
            )
            .insertBefore(
              "nav.user-registration-MyAccount-navigation ul li:eq(5)"
            );
          $(
            '<li><a href="' +
              siteurl +
              '/minha-conta/as-minhas-votacoes-fc/">Votações Projetos FC</a></li>'
          )
            .addClass(
              "user-registration-MyAccount-navigation-link user-registration-MyAccount-navigation-link--my-votes-fc"
            )
            .insertBefore(
              "nav.user-registration-MyAccount-navigation ul li:eq(6)"
            );

          if ($(".as-minhas-ideias").length > 0) {
            $(".user-registration-MyAccount-navigation li:eq(4)").addClass(
              "is-active"
            );
          }

          if ($(".as-minhas-votacoes").length > 0) {
            $(".user-registration-MyAccount-navigation li:eq(5)").addClass(
              "is-active"
            );
          }

          if ($(".as-minhas-votacoes-fc").length > 0) {
            $(".user-registration-MyAccount-navigation li:eq(6)").addClass(
              "is-active"
            );
          }
        }

        if ($("form #user_registration_utilizador_valores_field").length > 0) {
          $("form #user_registration_utilizador_valores_field ul").html("");

          // na minha conta verifica se tem 3 valores selecionados e inativa todas as outras checkboxes
          function checkValoresSel() {
            if (
              $(
                "form #user_registration_utilizador_valores_field ul li input[type=checkbox]:checked"
              ).length == 3
            ) {
              $(
                "form #user_registration_utilizador_valores_field ul li input[type=checkbox]:not(:checked)"
              ).attr("disabled", true);
            } else {
              $(
                "form #user_registration_utilizador_valores_field ul li input[type=checkbox]:not(:checked)"
              ).attr("disabled", false);
            }
          }

          var data = {
            action: "get_valores",
            post_type: "POST",
            email: $("#user_registration_user_email").val(),
          };

          $.post(ajaxurl, data, function (response) {
            $("form #user_registration_utilizador_valores_field ul").html(
              response
            );
            checkValoresSel();
          });

          // só permite selecionar 3 valores
          $(document).on(
            "click",
            "form #user_registration_utilizador_valores_field ul li input[type=checkbox], form #user_registration_utilizador_valores_field ul li label",
            function () {
              checkValoresSel();
            }
          );
        }

        if ($("form select").length > 0) {
          $("form select").select2();
        }

        // move a label de erro para debaixo dos campos em caso de dropdown
        $('button[type="submit"]').click(function () {
          setTimeout(function () {
            $("form select").each(function () {
              var id = $(this).attr("id");
              $(this)
                .parent()
                .find("label#" + id + "-error")
                .detach()
                .insertAfter($(this).parent().find("span.select2-container"));
            });
          }, 50);
        });
      },
    },
    page_template_content_lista_ideias: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        var paged = 2;

        $(".filters select").select2({
          debug: false,
          dropdownParent: $(".filters"),
        });

        function getProjetos(id, projeto_sel, callback) {
          $("#projeto option:not(:first)").remove().end();

          var data = {
            action: "get_municipio_projetos",
            post_type: "POST",
            id: id,
            projeto_sel: projeto_sel,
          };

          $.post(ajaxurl, data, function (response) {
            $("#projeto").append(response);
            if (typeof callback === "function") {
              callback(); // Execute the callback function when the AJAX request completes
            }
          });
        }

        function filtraIdeias() {
          var concelho = $(".filters select#municipio").val();
          var projeto = $(".filters select#projeto").val();
          var valor = $(".filters select#valor").val();
          var area = $(".filters select#area").val();
          var texto = $(".filters input#pesquisa").val();

          // $("#div_ideas_results").html("");

          var data = {
            action: "get_ideias",
            post_type: "POST",
            origem: "lista",
            id: concelho,
            projeto: projeto,
            valor: valor,
            area: area,
            texto: texto,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            if (spl.length > 1) {
              $("#div_ideas_results")
                .html(spl[1])
                .removeClass("grid")
                .addClass("no-grid");
            } else {
              $("#div_ideas_results").html(response);
              $(".grid").masonry("destroy");
              initMasonry();
            }
          });
        }

        var url = location.href.split("?mun=");
        if (url[1]) {
          var spl = url[1].split("&");
          var concelho = spl[0];
          var projeto_sel = "";
          if (spl[1]) {
            var spl2 = spl[1].split("proj=");
            projeto_sel = spl2[1];
          }
          getProjetos(concelho, projeto_sel, function () {
            console.log("getProjetos finished, now calling another function!");
            // Call another function here
            filtraIdeias();
          });
        } else {
          $(".grid").masonry("destroy");
          initMasonry();
        }

        $(document).on("change", ".filters select", function () {
          filtraIdeias();
        });

        $(".search-icon").click(function () {
          filtraIdeias();
        });

        $("#municipio").change(function () {
          getProjetos($(this).val());
        });

        $(document).on("click", ".more a", function () {
          var concelho = $(".filters select#municipio").val();
          var projeto = $(".filters select#projeto").val();
          var valor = $(".filters select#valor").val();
          var area = $(".filters select#area").val();
          var texto = $(".filters input#pesquisa").val();
          var total = $(this).data("total");
          var posts_per_page = $(this).data("posts-page");

          var data = {
            action: "get_more_ideias",
            post_type: "POST",
            id: concelho,
            projeto: projeto,
            valor: valor,
            area: area,
            texto: texto,
            paged: paged,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            if (spl.length > 1) {
              $("#div_ideas_posts_results")
                .append(spl[1])
                .removeClass("grid")
                .addClass("no-grid");
            } else {
              $("#div_ideas_posts_results").append(response);
              $(".grid").masonry("destroy");
              initMasonry();
            }
            if (paged * posts_per_page >= total) {
              $(".more").hide();
            }
            paged++;
          });
        });
      },
    },
    submeter_ideia: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // verifica se não pode submeter algum tipo de ideia para esconder
        var ideias_1 = $("input[name=ideias_1]").val();
        var ideias_2 = $("input[name=ideias_2]").val();

        if (ideias_1 > 1) {
          $(".ideias_tipo .wpcf7-radio .wpcf7-list-item.last label").trigger(
            "click"
          );
          $(".ideias_tipo .wpcf7-radio .wpcf7-list-item.first").remove();
        }

        if (ideias_2 > 2) {
          $(".ideias_tipo .wpcf7-radio .wpcf7-list-item.last").remove();
        }

        // verificações após preenchimento de emails para ideias de grupo
        // existem vários cenários:
        // 1º um dos emails é o mesmo da conta atual e não permite submeter
        // 2º é utilizador mas já tem 3 ideias de grupo aprovadas/não aprovadas e não deixa submeter
        // 3º tem conta mas não é utilizador, também não permite submeter
        function verificaEmail(id, email) {
          var email = $("input[name=ideias_emails_email_" + id + "]").val();
          $(".wpcf7-submit").attr("disabled", true);

          var data = {
            action: "verifica_email",
            post_type: "POST",
            email: email,
          };

          $.post(ajaxurl, data, function (response) {
            if (response) {
              $("#span_email_" + id).html(response);
            } else {
              $("#span_email_" + id).html("");
            }
            verificaAvisos();
          });
        }

        // caso nenhum campo de email tenha avisos deixa submeter, senão bloqueia o botão
        function verificaAvisos() {
          if (
            $("#span_email_1").html() == "" &&
            $("#span_email_2").html() == "" &&
            $("#span_email_3").html() == "" &&
            $("#span_email_4").html() == "" &&
            $("#span_email_5").html() == "" &&
            $("#span_email_6").html() == ""
          ) {
            if ($("input[name=ideias_aceita]").prop("checked") == true) {
              $(".wpcf7-submit").attr("disabled", false);
            }
          } else {
            $(".wpcf7-submit").attr("disabled", true);
          }
        }

        /*// depois de preencher o campo verifica se o outro não tem o mesmo email, se não tiver valida
        $("input[name=ideias_emails_email_1]").on("blur", function () {
          if ($(this).val() != "" && $("input[name=ideias_emails_email_2]").val() != "" && $("input[name=ideias_emails_email_2]").val() == $(this).val()) {
            $("#span_email_1").html("Os emails não podem ser iguais.");
            verificaAvisos();
          } else {
            verificaEmail(1);
          }
        });

        // depois de preencher o campo verifica se o outro não tem o mesmo email, se não tiver valida
        $("input[name=ideias_emails_email_2]").on("blur", function () {
          if ($(this).val() != "" && $("input[name=ideias_emails_email_1]").val() != "" && $("input[name=ideias_emails_email_1]").val() == $(this).val()) {
            $("#span_email_2").html("Os emails não podem ser iguais.");
            verificaAvisos();
          } else {
            verificaEmail(2);
          }
        });*/

        // verifica se existem emails iguais e se maior que 0 não pode deixar submeter
        function checkEmail(field_id, email) {
          var encontrado = 0;

          $("input[name^='ideias_emails_email']").each(function () {
            if ($(this).val() != "") {
              var id = $(this).attr("name").split("_");
              if (id[id.length - 1] != field_id) {
                var value = $(this).val();
                if (value == email) {
                  encontrado++;
                }
              }
            }
          });

          return encontrado;
        }

        // depois de preencher um campo verifica se os outros não têm o mesmo email, e se não tiver valida
        $("input[name^='ideias_emails_email']").on("blur", function () {
          var id = $(this).attr("name").split("_");
          var encontrado = 0;

          if ($(this).val() != "") {
            encontrado = checkEmail(id[id.length - 1], $(this).val());
          }

          if (!encontrado) {
            verificaEmail(id[id.length - 1]);
          } else {
            $("#span_email_" + id[id.length - 1]).html(
              "Todos os emails têm de ser diferentes."
            );
            verificaAvisos();
          }
        });

        // caso selecione a opção aceita e é uma ideia de grupo valida os emails preenchidos
        $("input[name=ideias_aceita]").on("change", function () {
          if (
            $(this).prop("checked") == true &&
            $("input[name=ideias_tipo]:checked").val() == "Ideia de grupo"
          ) {
            $("input[name^='ideias_emails_email']").each(function () {
              var id = $(this).attr("name").split("_");
              verificaEmail(id[id.length - 1]);
            });
          }
        });

        // caso mude para ideia individual limpa os campos emails e avisos, e ativa o botão de submite se a opção aceita está selecionada
        $("input[name=ideias_tipo]").on("click", function () {
          if ($(this).val() == "Ideia individual") {
            $(
              "input[name=ideias_emails_email_1], input[name=ideias_emails_email_2]"
            ).val("");
            $("#span_email_1, #span_email_2").html("");
            if ($("input[name=ideias_aceita]").prop("checked") == true) {
              $(".wpcf7-submit").attr("disabled", false);
            }
          }
        });
      },
    },
    as_minhas_ideias: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        $(".approve-email").click(function () {
          var ideia = $(this).data("ideia");
          var email_num = $(this).data("email");

          if (
            confirm(
              "Tem a certeza que aprova o convite para esta ideia? Esta operação é irreversível."
            )
          ) {
            var data = {
              action: "aprova_email",
              post_type: "POST",
              ideia: ideia,
              email_num: email_num,
            };

            $.post(ajaxurl, data, function (response) {
              if (response == "1") {
                location.reload();
              } else {
                alert(
                  "Ocorreu um erro ao aprovar o email, por favor tente mais tarde."
                );
              }
            });
          }
        });
      },
    },
    as_minhas_votacoes: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        $(".remove-vote").click(function () {
          var voto = $(this).data("voto");

          if (
            confirm(
              "Tem a certeza que quer enviar o pedido de remoção do voto? Esta operação é irreversível."
            )
          ) {
            var data = {
              action: "remover_voto",
              post_type: "POST",
              voto: voto,
            };

            $.post(ajaxurl, data, function (response) {
              if (response == "1") {
                location.reload();
              } else {
                alert(
                  "Ocorreu um erro ao aprovar o email, por favor tente mais tarde."
                );
              }
            });
          }
        });
      },
    },
    page_template_content_fora_do_comum: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        function filtraFC(concelho) {
          var data = {
            action: "get_evento",
            post_type: "POST",
            concelho: concelho,
          };

          $.post(ajaxurl, data, function (response) {
            $("#div_results").html(response);

            var swiper = new Swiper(".slideshow-wrapper .swiper-container", {
              slidesPerView: 1,
              spaceBetween: 30,
              loop: true,
              watchOverflow: true,
              autoplay: {
                delay: 2500,
              },
              pagination: {
                el: ".slideshow-wrapper .swiper-container .swiper-pagination",
                type: "bullets",
                clickable: true,
              },
            });

            initMasonry();

            if ($(".projets-list").length > 0) {
              var ja_votou = false;

              $(document).on("click", "a.project-vote", function (event) {
                event.preventDefault();

                if (!ja_votou) {
                  ja_votou = true;

                  if (
                    confirm(
                      "Deseja votar neste projeto? A operação é irreversível!"
                    )
                  ) {
                    var projeto = $(this).data("projeto");
                    var url_atual = window.location.href;

                    $("a.project-vote").css("pointer-events", "none");

                    var data = {
                      action: "votar_projeto",
                      post_type: "POST",
                      projeto: projeto,
                    };

                    $.post(ajaxurl, data, function (response) {
                      if (response == 1) {
                        //location.reload();
                        window.location.replace(url_atual);
                      }
                    });
                  }
                }
              });
            }
          });
        }
        if ($("#div_results").length > 0) {
          var concelho = $('select[name="municipio"]').val();
          filtraFC(concelho);
        }

        $('select[name="municipio"]').change(function () {
          filtraFC($(this).val());
        });
      },
    },
    as_minhas_votacoes_fc: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        $(".remove-vote").click(function () {
          var voto = $(this).data("voto");

          if (
            confirm(
              "Tem a certeza que quer enviar o pedido de remoção do voto? Esta operação é irreversível."
            )
          ) {
            var data = {
              action: "remover_voto_fc",
              post_type: "POST",
              voto: voto,
            };

            $.post(ajaxurl, data, function (response) {
              if (response == "1") {
                location.reload();
              } else {
                alert(
                  "Ocorreu um erro ao aprovar o email, por favor tente mais tarde."
                );
              }
            });
          }
        });
      },
    },
    page_template_content_lugar_comum: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        if ($("select[name='dates']").length > 0) {
          $("select[name='dates']").select2({
            minimumResultsForSearch: -1,
          });
        }

        if ($("select.project_filter").length > 0) {
          $("select.project_filter").select2();
        }

        // filtra eventos
        $("select[name='dates']").change(function () {
          var tipo = $(this).val();

          var data = {
            action: "filter_eventos",
            post_type: "POST",
            tipo: tipo,
          };

          $.post(ajaxurl, data, function (response) {
            $("#div_events_results").html(response);
          });
        });

        // filtra projetos
        function filtraProjetosFC() {
          var projeto = $("select[name='project']").val();
          var valor = $("select[name='value']").val();

          var data = {
            action: "filter_projectos_fc",
            post_type: "POST",
            projeto: projeto,
            valor: valor,
          };

          $.post(ajaxurl, data, function (response) {
            $("#div_projects_results").html(response);
          });
        }

        $("select.project_filter").change(function () {
          filtraProjetosFC();
        });

        // verifica se tem parâmetro "proj" para selecionar na dropdown dos municípios
        var url = location.href;
        if (url.indexOf("?proj=") != -1) {
          var proj = url.split("?proj=");
          $("select[name='project']").val(proj[1]).trigger("change");
          $("html,body").animate(
            { scrollTop: $(".projets-container").offset().top - 100 },
            "slow"
          );
        }

        // ver mais notícias
        $(document).on(
          "click",
          ".event-wrapper .text-wrapper.has-news",
          function () {
            var id = $(this).data("event");
            if (!$(this).hasClass("open")) {
              $(this).addClass("open");
              $("#event_" + id).slideDown();
            } else {
              $(".events-news").slideUp();
              $(".event-wrapper .text-wrapper").removeClass("open");
            }
          }
        );

        $("#see_more").click(function () {
          $(".hide").removeClass("hide");
          $(this).css("display", "none");
        });
      },
    },
    single_projetos_fc: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        if ($("#go-back").length > 0) {
          document
            .getElementById("go-back")
            .addEventListener("click", function () {
              history.back();
            });
        }

        if ($(".gallery").length > 0) {
          setTimeout(function () {
            $(".grid").masonry({
              itemSelector: ".grid-item",
              gutter: 30,
            });
          }, 300);
        }

        // ver mais notícias
        $("#see_more").click(function () {
          $(".hide").removeClass("hide");
          $(this).css("display", "none");
        });
      },
    },
    page_template_content_oficinas: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        $("div.ativity div.menu a.menu-anchor").click(function () {
          var anchor = $(this).attr("href");
          console.log(anchor);

          $("html, body")
            .stop()
            .animate(
              {
                scrollTop:
                  $(anchor).offset().top - $("header.sticky").outerHeight(true),
              },
              800
            );
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function initMasonry() {
    $(".grid").masonry({
      itemSelector: ".grid-item",
      gutter: 10,
    });
  }
})(jQuery); // Fully reference jQuery after this point.
